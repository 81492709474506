import { AuthenicationContainer } from "@features/authentication";
import { render } from "preact";
import App from "./App";

import "@assets/style.css";

render(
	<AuthenicationContainer>
		<App />
	</AuthenicationContainer>,
	document.getElementById("app"),
);
